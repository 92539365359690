import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from '../PreviewCompatibleImage';

const Grid = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    gap: '30px 24px',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    gap: '24px',
  },
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '50px 24px',
}));

const GridItem = styled(Link)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
  },
  display: 'block',
  cursor: 'pointer',
  color: 'inherit',
}));

const GridItemImage = styled('header')(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '5px',
  transition: 'opacity 0.2s ease 0s',
  '&:hover': {
    opacity: 0.8,
  },
}));

export function BlogPost({ post }) {
  return (
    <GridItem
      to={post.fields.slug}
    >
      <article>
        {post.frontmatter.featuredimage ? (
          <GridItemImage>
            <PreviewCompatibleImage
              imageInfo={{
                image: post.frontmatter.featuredimage,
                alt: `featured image thumbnail for post ${post.frontmatter.title}`,
              }}
            />
          </GridItemImage>
        ) : null}
        <Box fontSize={14} mt={2} mb={1}>
          {post.frontmatter.author.name} {' | '} {post.frontmatter.date}
        </Box>
        <Box fontWeight="fontWeightBold" fontSize={22} mb={1}>
          {post.frontmatter.title}
        </Box>
        <Box fontWeight={500} fontSize={18}>
          {post.frontmatter.description}
        </Box>
      </article>
    </GridItem>
  );
}

BlogPost.propTypes = {
  post: PropTypes.object,
};

export function BlogList({ data }) {
  const { edges: posts } = data.allMdx;
  return (
    <Grid>
      {posts
        && posts.map(({ node: post }) => (
          <BlogPost key={`blog-post-${post.id}`} post={post} />
        ))}
      {posts && posts.length === 0
        && <Typography
          variant="h5"
          component="h5"
        >
          Posts coming soon :)
        </Typography>
      }
    </Grid>
  );
}

BlogList.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`query BlogRollQuery {
  allMdx(
    sort: {order: DESC, fields: [frontmatter___date]}
    filter: {frontmatter: {templateKey: {eq: "blog-post"}, draft: {eq: false}}}
  ) {
    edges {
      node {
        excerpt(pruneLength: 400)
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          templateKey
          date(formatString: "MMMM DD, YYYY")
          author {
            id
            name
            bio
            twitter
          }
          featuredpost
          featuredimage {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`}
    render={(data, count) => <BlogList data={data} count={count} />}
  />
);
