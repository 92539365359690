import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Tabs, Tab } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function BlogSectionTabs({ tabs, selectedTab }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const variant = isSmallScreen ? 'scrollable' : null;
  const tabValue = tabs.findIndex((t) => t.id === selectedTab);
  return (
    <Tabs
      variant={variant}
      value={tabValue}
      indicatorColor="primary"
      scrollButtons
      aria-label="tabs"
      allowScrollButtonsMobile>
      {(tabs || []).map(({ id, label, to }, i) => (
        <Tab
          key={`tab_${id}_${i}`}
          label={label}
          component={Link}
          to={to}
        />
      ))}
    </Tabs>
  );
}

BlogSectionTabs.propTypes = {
  tabs: PropTypes.array,
  selectedTab: PropTypes.string,
};

BlogSectionTabs.defaultProps = {
  tabs: [
    {
      id: 'blog',
      label: 'Blog',
      to: '/blog',
    },
    {
      id: 'news',
      label: 'News',
      to: '/blog/section/news',
    },
    {
      id: 'product',
      label: 'Product',
      to: '/blog/section/product',
    },
    {
      id: 'learn',
      label: 'Learn',
      to: '/blog/section/learn',
    },
    {
      id: 'teach',
      label: 'Teach',
      to: '/blog/section/teach',
    },
  ],
  selectedTab: 'blog',
};

export default BlogSectionTabs;
