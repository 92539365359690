import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { capitalize } from 'lodash';
import Layout from '../layouts/Default';
import Section from '../components/Section';
import { BlogList } from '../components/BlogRoll/BlogRoll';
import BlogSectionTabs from '../components/BlogSectionTabs/BlogSectionTabs';

function SectionRoute(props) {
  const { section } = props.pageContext;
  const { title } = props.data.site.siteMetadata;
  return (
    <Layout>
      <Section
        sx={(theme) => ({
          paddingTop: theme.spacing(6),
          paddingBottom: theme.spacing(6),
        })}
      >
        <Helmet title={`${capitalize(section)} | ${title}`} />
        <BlogSectionTabs selectedTab={section} />
        <br />
        <BlogList data={props.data} />
      </Section>
    </Layout>
  );
}

SectionRoute.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default SectionRoute;

export const sectionPageQuery = graphql`query SectionPage($section: String) {
  site {
    siteMetadata {
      title
    }
  }
  allMdx(
    limit: 1000
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {sections: {in: [$section]}, draft: {eq: false}}}
  ) {
    totalCount
    edges {
      node {
        excerpt(pruneLength: 400)
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          templateKey
          date(formatString: "MMMM DD, YYYY")
          author {
            id
            name
            bio
            twitter
          }
          featuredpost
          featuredimage {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`;
